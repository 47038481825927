.error__header {
  background-color: #fff;
  color: white;
  height: 58px;
  margin: 10px;
}

.error-page__title {
  /*color: black;*/
  font-size: 2em;
  /*margin-left: 20%;*/
  position: relative;
  margin-top: 2em;
  text-align: center;
}
.error-page__title--error {
  /*color: black;*/
  font-size: 3em;
  /*margin-left: 20%;*/
  position: relative;
  margin-top: 2em;
  text-align: center;
}
.error-page__social--container {
  max-width: 300px;
  /*margin: 50px;*/
  text-align: center;
  margin: 100px auto;
  left: 0;
  right: 0;
}
.error-page__social--link {
  text-decoration: none;
  color: black;
}
